import portfolio1 from "../portfolioImgs/portfolio1.png";
import portfolio2 from "../portfolioImgs/portfolio2.png";
import portfolio3 from "../portfolioImgs/portfolio3.png";
import portfolio4 from "../portfolioImgs/portfolio4.png";
import portfolio5 from "../portfolioImgs/portfolio5.png";
import portfolio6 from "../portfolioImgs/portfolio6.jpg";

export default {
    portfolio1,
    portfolio2,
    portfolio3,
    portfolio4,
    portfolio5,
    portfolio6,
};
